.article {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: $borderRadius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &--gallery {
    margin-bottom: 0;
  }

  &.article--live.article--noImage {
    display: flex;
    justify-content: flex-start;
  }

  a.article__link {
    display: flex;
    height: 100%;
  }

  &__img-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__img-container img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__text-container {
    flex: 1;
    // default spacing below image added to container top
    margin: $gapStandard;
    padding: 0;

    &.no-margin {
      margin: 0;
    }
  }

  &.article--medium a,
  &.article--large a {
    flex-direction: column;

    // Edge
    @supports (-ms-accelerator: true) {
      display: block;
    }

    // IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }
  }

  &.article--small a {
    flex-direction: row;
  }

  &.article--full-width {
    width: 100%;
  }

  .article__titlesAndAuthor-container {
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }

  &.article--breaking {
    .article__text-container {
      .article__section {
        margin-bottom: $breakingLabelMargin;
        padding: 0;
        display: inline-block;
        &::before {
          background-color: $colorLive;
          line-height: 1.5em; // Same as kicker label big
          content: 'Nå';
          color: white;
          padding: 2px 5px 2px 5px;
          border-radius: $borderRadiusTiny;
          position: relative;
          margin-right: 0;
          width: auto;
          height: auto;
        }
      }
      .article__kicker-title {
        @include articleTitle-kicker-label-big();
        font-weight: 500;
        display: inline;
      }
      .article__section {
        @include articleTitle-section-label-big();
      }
    }

    &.article--full-width {
      .article__img-container-container {
        border-right-width: 4px;
        border-right-style: solid;
        border-right-color: $colorLiveLine;
      }

      .article__img-container,
      .article__img-container img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(.article--full-width) {
      .article__img-container {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        img {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &.article--dark {
      .article__text-container {
        .article__section::before {
          background-color: $darkskinBreakingLabel;
        }
      }
      &.article--full-width {
        .article__img-container-container {
          border-right-color: $darkskinBreakingLine;
        }
      }

      &:not(.article--full-width) {
        .article__img-container {
          border-bottom-color: $darkskinBreakingLine;
        }
      }
    }
  }

  &.article--shortVideoTeaser,
  &.article--podcast {
    .article__media-kicker {
      display: flex;
      gap: $kicker-gap;
      align-items: center;
      margin-bottom: var(--spacing-spacing-8);
      .kicker__icon-wrapper {
        background-color: var(--color-black-shade-5);
        padding: var(--spacing-spacing-4) var(--spacing-spacing-6);
        border-radius: $borderRadiusSmall;
        .video__icon {
          background-image: url('https://default.sacdn.no/rnd/video-icon.svg');
        }
        .podcast__icon {
          background-image: url('https://default.sacdn.no/rnd/podcast-icon.svg');
        }
        .video__icon,
        .podcast__icon {
          height: 22px;
          width: 22px;
          background-repeat: no-repeat;
          flex-direction: row;
          background-size: contain;
          background-position: center;
        }
      }
      .media-kicker-title {
        font-family: $fontSansSerif;
        font-size: 0.9375rem;
        line-height: 1.125rem;
        font-weight: 600;

        .kicker-title {
          margin-left: $kicker-gap;
        }
      }
    }
    &.article--dark {
      .article__media-kicker {
        .kicker__icon-wrapper {
          background-color: var(--color-white-tint-20);
          .video__icon,
          .podcast__icon {
            filter: invert(1);
          }
        }
      }
    }
    @media screen and (max-width: $grid-width-tablet) {
      .article__media-kicker {
        .kicker__icon-wrapper {
          .video__icon,
          .podcast__icon {
            height: 18px;
            width: 18px;
          }
        }
        .media-kicker-title {
          font-size: 0.8125rem;
          line-height: 1rem;
        }
      }
    }
  }

  &.article--podcast {
    .podcast__image-container {
      float: right;
      display: block;
      overflow: hidden;
      margin-left: $gapStandard * 0.5;
      border-radius: $gapStandard * 0.5;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    }

    //Podcast icons BT and SA
    .podcast__image-container,
    .article__imageOrPodcast-container {
      display: block;
      .podcastImage {
        background-size: contain;
        background-repeat: no-repeat;
        height: 64px;
        width: 64px;
      }
      .podcastImage.hva-skjedde {
        background-image: url('https://cdn.bt.no/rnd/hva-skjedde_180x180.webp');
      }
      .podcastImage.hva-skjedde.premium {
        background-image: url('https://cdn.bt.no/rnd/hva-skjedde-green_180x180.webp');
      }
      .podcastImage.ballspark {
        background-image: url('https://cdn.bt.no/rnd/ballspark-2024_180x180.webp');
      }
      .podcastImage.nokon-maa-gaa {
        background-image: url('https://cdn.bt.no/rnd/nokon-maa-gaa_180x180.webp');
      }
      .podcastImage.naalebyen {
        background-image: url('https://cdn.bt.no/rnd/naalebyen_180x180.webp');
      }
      .podcastImage.exodus {
        background-image: url('https://cdn.bt.no/rnd/exodus_180x180.webp');
      }
      .podcastImage.knut-loeper {
        background-image: url('https://cdn.bt.no/rnd/knut-loeper.webp');
      }
      .podcastImage.aftenbla-bla {
        background-image: url('https://cdn.aftenbladet.no/rnd/aftenbla-bla_180x180_2025.webp');
      }
      .podcastImage.studio-a {
        background-image: url('https://cdn.aftenbladet.no/rnd/aftenball_180x180.webp');
      }
      .podcastImage.aftenball {
        background-image: url('https://cdn.aftenbladet.no/rnd/aftenball_180x180.webp');
      }
      .podcastImage.lunsj-med-lars {
        background-image: url('https://cdn.aftenbladet.no/rnd/lunsj-med-lars_180x180.webp');
      }
      .podcastImage.det-vi-lever-av {
        background-image: url('https://cdn.aftenbladet.no/rnd/det-vi-lever-av_180x180.webp');
      }
      .podcastImage.smellen {
        background-image: url('https://cdn.aftenbladet.no/rnd/smellen_180x180.webp');
      }
      .podcastImage.e24-podden {
        background-image: url('https://cdn.aftenbladet.no/rnd/e24-podden_180x180.webp');
      }
      .podcastImage.preg {
        background-image: url('https://cdn.aftenbladet.no/rnd/preg_180x180.webp');
      }
      .podcastImage.forbudte-stemmer {
        background-image: url('https://cdn.aftenbladet.no/rnd/forbudte-stemmer_180x180.webp');
      }
      .podcastImage.dommen {
        background-image: url('https://cdn.bt.no/rnd/dommen_180x180.webp');
      }
      .podcastImage.det-beste-for-barnet {
        background-image: url('https://cdn.bt.no/rnd/det-beste-for-barnet180x180.webp');
      }
      .podcastImage.nokon-maa-gaa-usa {
        background-image: url('https://cdn.bt.no/rnd/nokon-maa-gaa-usa-2024.webp');
      }
      .podcastImage.baron-og-bedrager {
        background-image: url('https://cdn.bt.no/rnd/podcast-covers/baronbedrag-180x180.webp');
      }
      .podcastImage.ung-spiller {
        background-image: url('https://cdn.bt.no/rnd/podcast-covers/ung-spiller-new-180x180.webp');
      }
      .podcastImage.tanken-bak {
        background-image: url('https://cdn.aftenbladet.no/rnd/podcast-covers/tanken-bak-180x180.webp');
      }
      .podcastImage.kvinna-i-bagasjerommet {
        background-image: url('https://cdn.bt.no/rnd/kvinnen-i-bagasjerommet-180x180.webp');
      }
      .podcastImage.maaaaaal {
        background-image: url('https://cdn.aftenbladet.no/rnd/podcast-covers/maaal-180x180.webp');
      }
      .podcastImage.fanget-paa-fjellet {
        background-image: url('https://cdn.bt.no/rnd/podcast-covers/fanget-paa-fjellet-180x180.webp');
      }
    }
  }

  &.article-section--btmeninger-kommentar {
    .article__title {
      // since section label and kicker are inline, add margin to the title
      margin-top: $gapSmall;
    }
  }

  .article__section-kicker-container {
    display: flex;
    margin-bottom: 4px;
    .article__kicker-title {
      margin-bottom: 0;
      color: $colorText;
    }
  }
  &.article--dark {
    .article__section-kicker-container {
      .article__kicker-title {
        color: #f2f2f2;
      }
    }
  }
  &.article--bt-inviterer {
    background-color: #eef9ff;
    color: #333;
    .article__img-container {
      height: 0;
      padding-bottom: 56.25%;

      img {
        height: auto;
      }
    }
    .article__text-container {
      margin: 12px;
      .article__section {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
      .article__title {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
        letter-spacing: -0.03em;
        .bt-inviterer-label {
          font-family: TiemposTextRegular;
          font-weight: 300;
        }
      }
      .article__kicker-title {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
        margin: 0;
      }
      .article__img-container {
        float: right;
      }
    }
    .article-divider {
      height: 1px;
      width: calc(100% - 24px);
      background: #ddd;
      margin: 0 auto;
    }
    .bt-inviterer-subteaser a {
      padding: $gapStandard;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .subteaser-text-container {
        .article__section,
        .article__title {
          font-family: Inter;
        }
        .article__section {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 4px;
        }
        .article__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .article-image {
        height: 85px;
        width: 128px;
        background: url('#{$cdnHost}/bt/inviterer/bt_inviterer_illustration.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: $borderRadius;
      }
    }
  }
  &--gallery {
    margin-bottom: 0 !important;
    .article-gallery {
      &-container {
        position: relative;
        .article-gallery-container-slider {
          cursor: pointer;
          display: flex;
          overflow: scroll;
          scroll-snap-type: x mandatory;

          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }

          img {
            width: 100%;
            min-width: 100%;
            height: auto;
            scroll-snap-align: start;
          }
        }
        .article-gallery-progress {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          gap: 0.5rem;
          bottom: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
          padding: 0.5rem 0.5rem;
          border-radius: $borderRadiusSmall;
          background-color: hsla(0, 0%, 0%, 0.5);

          &-dot {
            border-radius: 50%;
            height: 0.75rem;
            width: 0.75rem;
            background-color: hsla(0, 0%, 100%, 0.2);
            transition: all 0.1s linear;

            &.active {
              background-color: #fff;
            }
            &.hidden {
              display: none;
            }

            &.small {
              height: calc(0.75rem - 4px);
              width: calc(0.75rem - 4px);
            }

            &.micro {
              height: calc(0.75rem - 8px);
              width: calc(0.75rem - 8px);
            }
          }
        }
        .article-gallery-controllers {
          position: absolute;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 30%;
          height: 100%;
          box-sizing: border-box;
          border: none;
          background-color: none;
          background: none;

          @media screen and (max-width: 500px) {
            width: 10%;
          }

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
            justify-content: flex-end;
          }

          &.disabled {
            svg {
              fill: #8c8c8c;
            }
          }

          &--icon {
            margin: 0 1.2rem;
            aspect-ratio: 1/1;
            height: 20%;

            max-height: 3rem;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 500px) {
              display: none;
            }

            svg {
              height: 1.2rem;
              width: 1.2rem;
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
a:focus {
  outline-offset: -1px !important;
}

// Timeline items need larger offset than normal articles
.article--live .timeline--item {
  a:focus {
    outline-offset: 5px !important;
  }
}
